.fab {
  position: fixed;
  right: 30px;
  bottom: 30px;
}

.active-nav {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, rgb(35 37 64) 0%, rgb(16 20 57) 35%, rgb(2 3 18) 100%);
  /* background: linear-gradient(135deg, rgba(92,94,117,1) 0%, rgba(65,69,117,1) 35%, rgba(23,25,59,1) 100%); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiAutocomplete-popper {
  background-color: #404154;
}
